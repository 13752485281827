const quizModels = {
    
    "Informatique de base": {
      "questions": [
        {
          "question": "Quel est l'acronyme du processeur central ?",
          "choices": ["GPU", "RAM", "CPU", "SSD"],
          "correctAnswer": 2
        },
        {
          "question": "Lequel des périphériques suivants est un périphérique d'entrée ?",
          "choices": ["Écran", "Souris", "Haut-parleurs", "Imprimante"],
          "correctAnswer": 1
        },
        {
          "question": "Qu'est-ce que la mémoire vive (RAM) ?",
          "choices": ["Mémoire permanente", "Mémoire temporaire", "Stockage de données", "Processeur"],
          "correctAnswer": 1
        },
        {
          "question": "Quel est le système de base de l'ordinateur ?",
          "choices": ["Système d'exploitation", "Carte mère", "Disque de stockage", "Processeur"],
          "correctAnswer": 0
        },
        {
          "question": "Quel est le rôle du disque dur dans un ordinateur ?",
          "choices": ["Entrée de données", "Traitement de données", "Stockage de données", "Affichage de données"],
          "correctAnswer": 2
        },
        {
          "question": "Quel type de mémoire est utilisée pour stocker les données temporairement pendant que l'ordinateur est allumé ?",
          "choices": ["SSD", "RAM", "HDD", "ROM"],
          "correctAnswer": 1
        },
        {
          "question": "Quel composant d'un ordinateur est responsable du traitement des graphiques et des images ?",
          "choices": ["CPU", "GPU", "RAM", "SSD"],
          "correctAnswer": 1
        },
        {
          "question": "Quel système d'exploitation est principalement utilisé sur les ordinateurs Apple ?",
          "choices": ["Windows", "Linux", "macOS", "Android"],
          "correctAnswer": 2
        },
        {
          "question": "Lequel des éléments suivants est un périphérique de sortie ?",
          "choices": ["Clavier", "Souris", "Écran", "Scanner"],
          "correctAnswer": 2
        },
        {
          "question": "Quel est le rôle principal de la carte mère dans un ordinateur ?",
          "choices": ["Stocker les données", "Gérer l'alimentation", "Connecter tous les composants", "Processeur"],
          "correctAnswer": 2
        },
        {
          "question": "Lequel de ces types de fichiers est un fichier exécutable sous Windows ?",
          "choices": [".exe", ".jpg", ".txt", ".pdf"],
          "correctAnswer": 0
        },
        {
          "question": "Qu'est-ce qu'un réseau local (LAN) ?",
          "choices": ["Un réseau qui couvre une grande région géographique", "Un réseau qui connecte des appareils dans une zone restreinte", "Un réseau sans fil", "Un réseau de communication par satellite"],
          "correctAnswer": 1
        },
        {
          "question": "Quelle est la fonction d'un routeur dans un réseau informatique ?",
          "choices": ["Stocker des données", "Protéger contre les virus", "Acheminer des données entre différents réseaux", "Fournir de l'énergie aux appareils"],
          "correctAnswer": 2
        },
        {
          "question": "Quel type de mémoire est généralement utilisé pour stocker le BIOS d'un ordinateur ?",
          "choices": ["ROM", "RAM", "SSD", "GPU"],
          "correctAnswer": 0
        },
        {
          "question": "Lequel de ces logiciels est un navigateur web ?",
          "choices": ["Microsoft Word", "Adobe Photoshop", "Mozilla Firefox", "VLC Media Player"],
          "correctAnswer": 2
        },
        {
          "question": "Quelle est la différence entre HTTP et HTTPS ?",
          "choices": ["HTTPS est plus rapide que HTTP", "HTTPS est sécurisé avec un chiffrement", "HTTP est utilisé pour les fichiers multimédias", "HTTP est uniquement utilisé pour les connexions locales"],
          "correctAnswer": 1
        }
      ]
    },
  
  
    
        "Laravel": {
          "questions": [
            {
              "question": "Qu'est-ce que Laravel ?",
              "choices": [
                "Un framework JavaScript",
                "Un framework PHP",
                "Un éditeur de code",
                "Un système de gestion de contenu"
              ],
              "correctAnswer": 1
            },
            {
              "question": "Quel est le gestionnaire de dépendances utilisé par Laravel ?",
              "choices": [
                "NPM",
                "Composer",
                "Yarn",
                "Pip"
              ],
              "correctAnswer": 1
            },
            {
              "question": "Quelle commande est utilisée pour créer un nouveau projet Laravel ?",
              "choices": [
                "laravel new nom_projet",
                "composer create-project --prefer-dist laravel/laravel nom_projet",
                "php artisan new nom_projet",
                "npm init laravel nom_projet"
              ],
              "correctAnswer": 1
            },
            {
              "question": "Quel fichier contient les routes de l'application Laravel ?",
              "choices": [
                "web.php",
                "routes.php",
                "app.php",
                "config.php"
              ],
              "correctAnswer": 0
            },
            {
              "question": "Quel est le modèle de conception utilisé par Laravel ?",
              "choices": [
                "MVC",
                "MVVM",
                "MVP",
                "MVT"
              ],
              "correctAnswer": 0
            },
            {
              "question": "Quelle commande artisan est utilisée pour exécuter les migrations ?",
              "choices": [
                "php artisan migrate",
                "php artisan migrate:run",
                "php artisan db:migrate",
                "php artisan migrate:execute"
              ],
              "correctAnswer": 0
            },
            {
              "question": "Quel est le moteur de template utilisé par Laravel ?",
              "choices": [
                "Smarty",
                "Twig",
                "Blade",
                "Jinja"
              ],
              "correctAnswer": 2
            },
            {
              "question": "Comment appelle-t-on le système de routage dans Laravel ?",
              "choices": [
                "Routing",
                "Pathing",
                "Mapping",
                "Linking"
              ],
              "correctAnswer": 0
            },
            {
              "question": "Quel est le fichier de configuration principal de Laravel ?",
              "choices": [
                ".env",
                "config/app.php",
                "database.php",
                "app.php"
              ],
              "correctAnswer": 0
            },
            {
              "question": "Quel est l'utilitaire de ligne de commande fourni par Laravel ?",
              "choices": [
                "laracast",
                "laravel-cli",
                "artisan",
                "laravel-console"
              ],
              "correctAnswer": 2
            }
          ]
        },
        
            "Gestion des données": {
              "questions": [
                {
                  "question": "Que signifie la gestion des données ?",
                  "choices": [
                    "Organisation et stockage des données",
                    "Cryptage des données",
                    "Suppression des données",
                    "Compression des données"
                  ],
                  "correctAnswer": 0
                },
                {
                  "question": "Lequel des éléments suivants est un moyen de stocker des données ?",
                  "choices": [
                    "Processeur",
                    "Disque dur",
                    "Souris",
                    "Écran"
                  ],
                  "correctAnswer": 1
                },
                {
                  "question": "Quel est le rôle d'une base de données ?",
                  "choices": [
                    "Afficher des graphismes",
                    "Saisir des données",
                    "Organiser des données",
                    "Exécuter des programmes"
                  ],
                  "correctAnswer": 2
                },
                {
                  "question": "Quel type de base de données est utilisé pour stocker des données sous forme de tableaux ?",
                  "choices": [
                    "Base de données relationnelle",
                    "Base de données NoSQL",
                    "Base de données orientée objet",
                    "Base de données graphique"
                  ],
                  "correctAnswer": 0
                },
                {
                  "question": "Quelle commande SQL est utilisée pour extraire des données d'une base de données ?",
                  "choices": [
                    "INSERT",
                    "UPDATE",
                    "SELECT",
                    "DELETE"
                  ],
                  "correctAnswer": 2
                },
                {
                  "question": "Qu'est-ce qu'un système de gestion de base de données (SGBD) ?",
                  "choices": [
                    "Un logiciel pour créer des images",
                    "Un logiciel pour gérer les données",
                    "Un langage de programmation",
                    "Un système d'exploitation"
                  ],
                  "correctAnswer": 1
                },
                {
                  "question": "Quel est l'objectif principal de la normalisation des données ?",
                  "choices": [
                    "Améliorer la sécurité des données",
                    "Réduire la redondance des données",
                    "Accélérer l'accès aux données",
                    "Faciliter le stockage des données"
                  ],
                  "correctAnswer": 1
                },
                {
                  "question": "Quelle est la différence entre une base de données et un fichier ?",
                  "choices": [
                    "Une base de données est plus lente",
                    "Une base de données est structurée",
                    "Un fichier peut stocker plus de données",
                    "Une base de données n'a pas de limite de taille"
                  ],
                  "correctAnswer": 1
                },
                {
                  "question": "Quel type de données peut être stocké dans une base de données ?",
                  "choices": [
                    "Texte uniquement",
                    "Numériques uniquement",
                    "Images uniquement",
                    "Tous les types de données"
                  ],
                  "correctAnswer": 3
                },
                {
                  "question": "Qu'est-ce qu'une clé primaire dans une base de données ?",
                  "choices": [
                    "Un identifiant unique pour chaque enregistrement",
                    "Un champ qui peut contenir des valeurs nulles",
                    "Un index pour améliorer les performances",
                    "Une contrainte de sécurité"
                  ],
                  "correctAnswer": 0
                }
              ]
            },
            
                "React": {
                  "questions": [
                    {
                      "question": "Qu'est-ce que React ?",
                      "choices": [
                        "Un langage de programmation",
                        "Une bibliothèque JavaScript pour construire des interfaces utilisateur",
                        "Un framework pour le développement backend",
                        "Un système de gestion de base de données"
                      ],
                      "correctAnswer": 1
                    },
                    {
                      "question": "Quel est le rôle des composants dans React ?",
                      "choices": [
                        "Stocker des données",
                        "Créer des applications mobiles",
                        "Diviser l'interface utilisateur en morceaux réutilisables",
                        "Gérer la sécurité des applications"
                      ],
                      "correctAnswer": 2
                    },
                    {
                      "question": "Comment crée-t-on un composant fonctionnel en React ?",
                      "choices": [
                        "Avec une classe JavaScript",
                        "Avec une fonction JavaScript",
                        "Avec un fichier HTML",
                        "Avec un fichier CSS"
                      ],
                      "correctAnswer": 1
                    },
                    {
                      "question": "Quel est l'état (state) dans un composant React ?",
                      "choices": [
                        "Un objet qui stocke les données d'un composant",
                        "Un élément du DOM",
                        "Une méthode pour afficher des données",
                        "Un événement utilisateur"
                      ],
                      "correctAnswer": 0
                    },
                    {
                      "question": "Comment peut-on modifier l'état d'un composant ?",
                      "choices": [
                        "Avec la méthode setState() ",
                        "Avec la méthode render()",
                        "Avec la méthode update()",
                        "Avec la méthode change()"
                      ],
                      "correctAnswer": 0
                    },
                    {
                      "question": "Quel hook est utilisé pour gérer l'état dans un composant fonctionnel ?",
                      "choices": [
                        "useEffect",
                        "useState",
                        "useContext",
                        "useReducer"
                      ],
                      "correctAnswer": 1
                    },
                    {
                      "question": "Qu'est-ce que le Virtual DOM dans React ?",
                      "choices": [
                        "Une version physique du DOM",
                        "Un système de stockage de données",
                        "Une représentation légère du DOM pour optimiser le rendu",
                        "Un outil de développement pour créer des composants"
                      ],
                      "correctAnswer": 2
                    },
                    {
                      "question": "Quel est le rôle du hook useEffect ?",
                      "choices": [
                        "Gérer les événements utilisateur",
                        "Effectuer des effets de bord après le rendu",
                        "Modifier le DOM directement",
                        "Récupérer des données de l'API"
                      ],
                      "correctAnswer": 1
                    },
                    {
                      "question": "Comment passer des données d'un composant parent à un composant enfant ?",
                      "choices": [
                        "Avec des props",
                        "Avec l'état",
                        "Avec le contexte",
                        "Avec les événements"
                      ],
                      "correctAnswer": 0
                    },
                    {
                      "question": "Qu'est-ce que JSX ?",
                      "choices": [
                        "Un langage de programmation",
                        "Une syntaxe permettant d'écrire du HTML dans JavaScript",
                        "Une bibliothèque pour la gestion des états",
                        "Un outil pour tester des composants"
                      ],
                      "correctAnswer": 1
                    }
                  ]
                },
                
                    "Cybersécurité": {
                      "questions": [
                        {
                          "question": "Qu'est-ce que la cybersécurité ?",
                          "choices": [
                            "La protection des systèmes informatiques contre les attaques",
                            "La création de logiciels malveillants",
                            "La gestion des réseaux sociaux",
                            "Le développement de jeux vidéo"
                          ],
                          "correctAnswer": 0
                        },
                        {
                          "question": "Quel est le principal objectif de la cybersécurité ?",
                          "choices": [
                            "Éduquer les utilisateurs",
                            "Protéger les données et les systèmes",
                            "Développer de nouvelles technologies",
                            "Accélérer la connexion Internet"
                          ],
                          "correctAnswer": 1
                        },
                        {
                          "question": "Quel type d'attaque consiste à tromper les utilisateurs pour qu'ils divulguent des informations sensibles ?",
                          "choices": [
                            "Phishing",
                            "DDoS",
                            "Malware",
                            "Ransomware"
                          ],
                          "correctAnswer": 0
                        },
                        {
                          "question": "Qu'est-ce qu'un pare-feu ?",
                          "choices": [
                            "Un logiciel de traitement de texte",
                            "Un dispositif de sécurité qui contrôle le trafic réseau",
                            "Un type de virus",
                            "Une méthode de stockage de données"
                          ],
                          "correctAnswer": 1
                        },
                        {
                          "question": "Quel est le rôle des mots de passe dans la cybersécurité ?",
                          "choices": [
                            "Augmenter la vitesse de l'ordinateur",
                            "Protéger l'accès aux comptes et aux données",
                            "Faciliter le partage d'informations",
                            "Remplacer les antivirus"
                          ],
                          "correctAnswer": 1
                        },
                        {
                          "question": "Quel type de logiciel malveillant chiffre les fichiers et demande une rançon pour les déchiffrer ?",
                          "choices": [
                            "Virus",
                            "Ransomware",
                            "Trojan",
                            "Spyware"
                          ],
                          "correctAnswer": 1
                        },
                        {
                          "question": "Quelle est l'importance des mises à jour logicielles en matière de cybersécurité ?",
                          "choices": [
                            "Elles ralentissent le système",
                            "Elles corrigent des vulnérabilités de sécurité",
                            "Elles consomment de la mémoire",
                            "Elles sont uniquement esthétiques"
                          ],
                          "correctAnswer": 1
                        },
                        {
                          "question": "Qu'est-ce que le cryptage ?",
                          "choices": [
                            "Un processus de codage des données pour les protéger",
                            "Un type de virus",
                            "Une méthode de partage de fichiers",
                            "Une technique de piratage"
                          ],
                          "correctAnswer": 0
                        },
                        {
                          "question": "Qu'est-ce qu'une attaque DDoS ?",
                          "choices": [
                            "Une attaque qui vise à rendre un service indisponible en le submergeant de trafic",
                            "Une attaque qui modifie des données",
                            "Une tentative d'accès non autorisé à des systèmes",
                            "Un type de phishing"
                          ],
                          "correctAnswer": 0
                        },
                        {
                          "question": "Quelle est la meilleure pratique pour créer un mot de passe sécurisé ?",
                          "choices": [
                            "Utiliser des mots courants",
                            "Inclure des chiffres et des symboles",
                            "Utiliser la même combinaison pour tous les comptes",
                            "Utiliser uniquement des lettres"
                          ],
                          "correctAnswer": 1
                        }
                      ]
                    }
                  
                  
              
              
          
          
      
      
};
export default quizModels;