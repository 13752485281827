// src/App.js
import React, { useState } from "react";
import './App.css';  // Importation du fichier CSS
import quizModels from './quizModels'; // Importation des modèles de quiz

function StartScreen({ onStart }) {
  return (
    <div className="StartScreen">
      <h2>Bienvenue sur l'application Quizizz</h2>
      <img src="/picture/Logo.png" alt="Logo" className="logo" />
      <p>Choisissez le modèle que vous souhaitez et cliquez sur "Commencer" pour démarrer le quiz.</p>
      <button onClick={onStart}>Commencer le quiz</button>
    </div>
  );
}

function ResultScreen({ score, totalQuestions }) {
  return (
    <div className="ResultScreen">
      <h2>Résultat</h2>
      <p>Votre score : {score} / {totalQuestions}</p>
      {/* Optionally, you can add a button to restart the quiz */}
    </div>
  );
}

function App() {
  const [model, setModel] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(0); // Initialize to the first question
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [score, setScore] = useState(0);
  const [isStarted, setIsStarted] = useState(false);

  const handlePlay = () => {
    setIsStarted(true);
    setCurrentQuestion(0); // Reset question index
    setScore(0); // Reset score
  };

  const handleModelChange = (event) => {
    setModel(event.target.value);
    setCurrentQuestion(0); // Reset question index when model changes
    setScore(0); // Reset score
    setShowResult(false); // Hide result if a new model is selected
  };

  const handleAnswerSelection = (index) => {
    setSelectedAnswer(index);
  };

  const handleNextQuestion = () => {
    if (selectedAnswer !== null) {
      // Check if the answer is correct
      if (quizModels[model].questions[currentQuestion].correctAnswer === selectedAnswer) {
        setScore(score + 1);
      }
      setSelectedAnswer(null); // Reset the selected answer
      if (currentQuestion < quizModels[model].questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1); // Move to the next question
      } else {
        setShowResult(true); // Show result when all questions are answered
      }
    }
  };

  return (
    <div className="App">
      <h1>Quizizz : Jouez et apprenez</h1>
      {!isStarted && <StartScreen onStart={handlePlay} />}
      {isStarted && !showResult && (
        <div>
          <label htmlFor="model">Choisissez un modèle de quiz : </label>
          <select id="model" onChange={handleModelChange}>
            <option value="">Sélectionner un modèle</option>
            {Object.keys(quizModels).map((quizModel, index) => (
              <option key={index} value={quizModel}>
                {quizModel}
              </option>
            ))}
          </select>

          {model && currentQuestion < quizModels[model].questions.length && (
            <div>
              <h2>{quizModels[model].questions[currentQuestion].question}</h2>
              <div>
                {quizModels[model].questions[currentQuestion].choices.map((choice, index) => (
                  <div key={index}>
                    <label>
                      <input
                        type="radio"
                        name="answer"
                        value={index}
                        onChange={() => handleAnswerSelection(index)}
                        checked={selectedAnswer === index}
                      />
                      {choice}
                    </label>
                  </div>
                ))}
              </div>
              <button onClick={handleNextQuestion} disabled={selectedAnswer === null}>
                Question suivante
              </button>
            </div>
          )}
        </div>
      )}

      {showResult && <ResultScreen score={score} totalQuestions={quizModels[model].questions.length} />}
    </div>
  );
}

export default App;
